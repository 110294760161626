import * as React from "react";
import { Contain } from "../common";
import Loading from "../../Loading";

const Spinner: React.FC = () => (
  <Contain>
    <Loading />
  </Contain>
);

export default Spinner;
