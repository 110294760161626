import * as React from "react";
import styled, { keyframes } from "styled-components";

const dashSpacing = 940;
const dashLength = 120;
const dashOffset = dashSpacing + dashLength;

const loadingMovement = keyframes`
 0% { stroke-dashoffset: 0 }
 100% { stroke-dashoffset: ${dashOffset} }
`;

const StyledSvg = styled.svg`
  #animate {
    stroke-dasharray: ${dashLength} ${dashSpacing};
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: ${loadingMovement} 1.5s linear infinite;
  }
`;

const Loading: React.FC = () => (
  <StyledSvg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="312px"
    height="312px"
    viewBox="150 240 312 312"
    enableBackground="new 0 0 612 792"
  >
    <path
      id="animate"
      fill="none"
      stroke="#58595B"
      strokeWidth="20"
      strokeMiterlimit="10"
      d="M305.095,307.56l-88.356,88.356L204,408.654
	c0,0-5.722,7.627-16.063,7.627s-20.365-7.865-20.365-20.365s10.024-20.358,20.69-20.358s16.071,7.953,16.071,7.953l100.762,100.762
	l14.401,14.401c0,0,5.965,5.226,5.965,14.4s-7.023,20.365-20.366,20.365s-20.366-11.396-20.366-20.365s5.965-14.4,5.965-14.4
	l117.159-117.159c0,0,4.661-5.965,14.742-5.965s20.023,9.173,20.023,20.356s-9.731,20.375-20.359,20.375s-14.406-5.964-14.406-5.964
	L290.693,293.159c0,0-5.965-4.559-5.965-14.401s8.285-20.366,20.366-20.366s20.366,10.27,20.366,20.366s-5.965,14.4-5.965,14.4
	L305.095,307.56z"
    />
  </StyledSvg>
);

export default Loading;
