import * as React from "react";
import { ThemeProvider } from "styled-components";

import { theme } from "./Components/GlobalStyle/theme";
import { GlobalStyleWrap } from "./Components/GlobalStyle";

import ErrorBoundary from "./Components/ErrorBoundary";
import Spinner from "./Components/Splashes/Spinner";

const Page = React.lazy(() => import("./Components/Page"));

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <ThemeProvider theme={theme}>
    <GlobalStyleWrap {...theme} />
    <ErrorBoundary>
      <React.Suspense fallback={<Spinner />}>
        <Page />
        {/* <Spinner /> */}
      </React.Suspense>
    </ErrorBoundary>
  </ThemeProvider>
);
