import styled from "styled-components";
import logo from "../../Resources/images/splash.png";

export const View = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #333;
  color: #fff;
`;
export const Contain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100vh;
  max-width: 50rem;
  margin: 0 auto;
  text-align: center;
`;

export const Image = styled(Contain)`
  background-image: url(${logo});
`;

export const Headline = styled.h1`
  font-weight: 300;
  font-style: italic;
  font-size: 3rem;
  line-height: 2.25rem;
  margin: 50vh 0 0.5em 0;
`;

export const SubHeadline = styled.p`
  text-align: center;
  max-width: 40rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.25rem;
`;
